import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PhotosWrapper from "../components/photos";

export default function Films() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFilms(sort: { fields: yearMade, order: DESC }) {
        edges {
          node {
            node_locale
            title
            slug
            yearMade(formatString: "YYYY")
            description {
              description
            }
            images {
              id
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  console.log({ data });
  return (
    <Layout>
      <SEO title="Photos" />
      <PhotosWrapper data={data} />
    </Layout>
  );
}
