import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import Img from "gatsby-image";
import "./films.css";
import ScrollDetector from "./ScrollDetector";

export default function Films({ data }) {
  const [visibility, setVisibility] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState(false);

  useEffect(() => {
    visibility &&
      setSelectedInfo(
        data.allContentfulFilms.edges.filter(
          (i) => visibility === i.node.slug
        )[0].node
      );
  }, [visibility]);

  function handleClick(value) {
    navigate(`/photos/${value}`);
  }

  function handleMouseOver(value) {
    setVisibility(value);
    setExpanded(!expanded);
  }

  function handleMouseLeave() {
    setVisibility(null);
    setExpanded(!expanded);
  }

  return (
    <>
      <div className="background-images">
        {data.allContentfulFilms &&
          data.allContentfulFilms.edges.map(
            (x) =>
              x.node.images && (
                <div
                  className={
                    visibility === x.node.slug
                      ? "film-image-wrapper-visible"
                      : "film-image-wrapper-invisible"
                  }
                >
                  <Img fluid={x.node.images[0].fluid} />
                </div>
              )
          )}
      </div>
      <ScrollDetector className="films-wrapper">
        <div className="film-titles-mobile">
          {data.allContentfulFilms.edges.map(
            (x) =>
              x.node.images && (
                <div
                  className={"button-deselected"}
                  onClick={() => handleClick(x.node.slug)}
                >
                  <h3 className="film-title-button">
                    {x.node.title.toUpperCase() + " "}
                  </h3>
                  <h5 className="film-year-button">{x.node.yearMade}</h5>
                </div>
              )
          )}
        </div>
        <div className="film-titles-desktop">
          {data.allContentfulFilms.edges.map(
            (x) =>
              x.node.images && (
                <div
                  className={
                    visibility === x.node.slug
                      ? "button-selected"
                      : "button-deselected"
                  }
                  value={x.node.slug}
                  onClick={() => handleClick(x.node.slug)}
                  onMouseEnter={() => handleMouseOver(x.node.slug)}
                  onMouseLeave={() => handleMouseLeave()}
                >
                  <h3 className="film-title-button">
                    {x.node.title.toUpperCase() + " "}
                  </h3>
                  {/* <h5 className="film-year-button">{x.node.yearMade}</h5> */}
                </div>
              )
          )}
        </div>
      </ScrollDetector>
    </>
  );
}
